import Divider from '@components/Divider'
import PageTitle from '@components/PageTitle'
import { Layout, Main, Stack } from '@layout'
import Seo from '@widgets/Seo'
import React from 'react'
import { Heading, Link, Text } from 'theme-ui'

const styles = {
  heading: {
    color: `alphaDarker`
  },
  right: {
    mt: 5,
    textAlign: `right`
  }
}

const PageContact = props => (
  <Layout {...props}>
    <Seo title='Contact' />
    <Divider />
    <Stack>
      <Main>
        <PageTitle header='Terms' subheader='' />
        Forestone(<Link href={`/`}>https://tech.fores-tone.co.jp</Link>
        )（以下、「当サイト」とします。）における免責事項は、下記の通りです。
        <Divider />
        <Heading variant='h4' sx={styles.heading}>
          【コメントについて】
        </Heading>
        次の各号に掲げる内容を含むコメントは、当サイト管理人の裁量によって承認せず、削除する事があります。
        特定の自然人または法人を誹謗し、中傷するもの
        極度にわいせつな内容を含むもの
        禁制品の取引に関するものや、他者を害する行為の依頼など、法律によって禁止されている物品、行為の依頼や斡旋などに関するもの
        その他、公序良俗に反し、または管理人によって承認すべきでないと認められるもの
        <Divider />
        <Heading variant='h4' sx={styles.heading}>
          【当サイトの情報の正確性について
        </Heading>
        当サイトのコンテンツや情報において、可能な限り正確な情報を掲載するよう努めています。しかし、誤情報が入り込んだり、情報が古くなったりすることもあります。必ずしも正確性を保証するものではありません。また合法性や安全性なども保証しません。
        <Divider />
        <Heading variant='h4' sx={styles.heading}>
          【損害等の責任について】
        </Heading>
        当サイトに掲載された内容によって生じた損害等の一切の責任を負いかねますので、ご了承ください。
        また当サイトからリンクやバナーなどによって他のサイトに移動された場合、移動先サイトで提供される情報、サービス等について一切の責任も負いません。
        当サイトの保守、火災、停電、その他の自然災害、ウィルスや第三者の妨害等行為による不可抗力によって、当サイトによるサービスが停止したことに起因して利用者に生じた損害についても、何ら責任を負うものではありません。
        当サイトを利用する場合は、自己責任で行う必要があります。
        当サイトで掲載している画像の著作権や肖像権等について
        当サイトで掲載している画像の著作権や肖像権等は、各権利所有者に帰属します。万が一問題がある場合は、お問い合わせよりご連絡いただけますよう宜しくお願い致します。
        <Divider />
        <Heading variant='h4' sx={styles.heading}>
          【無断転載の禁止について】
        </Heading>
        当サイトに存在する、文章や画像、動画等の著作物の情報を無断転載することを禁止します。引用の範囲を超えるものについては、法的処置を行います。転載する際には、お問い合わせよりご連絡いただけますよう宜しくお願い致します。
        <Text
          sx={{
            ...styles.right
          }}
        >
          2021年4月11日　策定
          <br />
          2021年4月11日　改訂
        </Text>
      </Main>
    </Stack>
  </Layout>
)

export default PageContact
